<template>
  <section class="legal">
    <h1 class="legal-title">
      Legal & tax information
    </h1>

    <div class="paragraph">
      <h3 class="paragraph-title">
        Certificate of incorporation
      </h3>
      <a target="_blank" href="/docs/UDEF-Certificate-of-Incorporation.pdf">
        UDEF Certificate of incorporation
      </a>
    </div>

    <div class="paragraph">
      <h3 class="paragraph-title">
        Form 990 filings
      </h3>
      <a
        v-for="doc in docs990"
        :key="doc.year"
        target="_blank"
        :href="doc.link"
      >
        {{ doc.title }}
      </a>
    </div>

    <div class="paragraph">
      <h3 class="paragraph-title">
        Non-profit status
      </h3>
      <a target="_blank" href="/docs/UDEF-IRS-Tax-Exempt-Application.pdf">
        UDEF IRS Tax Exempt Application
      </a>
      <a target="_blank" href="/docs/UDEF-IRS-Determination-Letter.pdf">
        UDEF IRS Determination Letter
      </a>
    </div>

    <div class="paragraph">
      <h3 class="paragraph-title">
        Organizational structure
      </h3>
      <a
        target="_blank"
        href="/docs/UDEF-Organizational-Structure-as-of-12.31.2018.pdf"
      >
        UDEF Organizational Structure Chart
      </a>
      <a
        target="_blank"
        href="/docs/UDEF-Board-of-Representatives-as-of-June.7.2021.pdf"
      >
        UDEF Board of Representatives
      </a>
    </div>

    <div class="paragraph">
      <h3 class="paragraph-title">
        Other
      </h3>
      <p>
        Other organizational information available on request
      </p>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Legal',
  data() {
    return {
      docs990: [
        {
          year: 2023,
          link: '/docs/UDEF_2023_990.pdf',
          title: 'UDEF Form 990 – 2023'
        },
        {
          year: 2022,
          link: '/docs/UDEF-2022-990.pdf',
          title: 'UDEF Form 990 – 2022'
        },
        {
          year: 2021,
          link: '/docs/UDEF-2021-990-PDC.pdf',
          title: 'UDEF Form 990 – 2021'
        },
        {
          year: 2020,
          link: '/docs/UDEF_2020_990_PDC.pdf',
          title: 'UDEF Form 990 – 2020'
        },
        {
          year: 2019,
          link: '/docs/UDEF_2019_990_PDC_signed_2.pdf',
          title: 'UDEF Form 990 – 2019'
        },
        {
          year: 2018,
          link: '/docs/UDEF-2018-990-PDC.pdf',
          title: 'UDEF Form 990 – 2018'
        },
        {
          year: 2017,
          link: '/docs/UDEF-2017-990.pdf',
          title: 'UDEF Form 990 – 2017'
        },
        {
          year: 2016,
          link: '/docs/UDEF-2016-990.pdf',
          title: 'UDEF Form 990 – 2016'
        },
        {
          year: 2015,
          link: '/docs/UDEF-2015-990.pdf',
          title: 'UDEF Form 990 – 2015'
        },
        {
          year: 2014,
          link: '/docs/UDEF-2014-990.pdf',
          title: 'UDEF Form 990 – 2014'
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/style/global.scss";

.legal {
  max-width: 880px;
  margin: auto;
  padding-top: 80px;

  @media screen and (max-width: $screen-md) {
    padding: 40px 20px 0 20px;
  }

  a {
    display: block;
  }

  .paragraph-title {
    font-weight: $font-weight-bold;
  }

  .paragraph {
    margin-bottom: 20px;
  }

  .legal-title {
    font-size: $font-section-title;
    font-weight: $font-weight-bold;
    margin-bottom: 20px;

    @media screen and (max-width: $screen-md) {
      font-size: $font-section-title-mobile;
    }
  }
}
</style>
